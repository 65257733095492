import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IHelmetBuyerGuideLadingState {
    categoryKey: string;
    bannerContent: string;
}

const categoryKeysHavingHelmetBuyerGuide = ['c-815', 'c-816', 'c-817', 'c-818', 'c-832', 'c-833', 'c-835', 'c-836', 'c-837', 'c-834', 'c-838', 'm-150'];

const HelmetBuyerGuideComponent: React.FunctionComponent<IHelmetBuyerGuideLadingState> = props => {
    const { categoryKey, bannerContent } = props;

    if (!categoryKeysHavingHelmetBuyerGuide.includes(categoryKey) || !bannerContent) {
        return null;
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: bannerContent }} />
    );
};

export const HelmetBuyerGuideLanding = withMemo(HelmetBuyerGuideComponent);
